<template>
  <div class="flex flex-col h-screen">
    <div class="flex-grow p-2">
      <slot />
    </div>

    <div class="hidden md:block bg-cyan-950 h-16 shadow">
      <div class="flex justify-center items-center h-16 overflow-y-scroll">
        <BottomMenuLink
          :icon="Squares2X2Icon"
          to="/"
          :active="route.path === '/'"
        >
          {{ $t("Home") }}
        </BottomMenuLink>

        <BottomMenuLink
          v-for="bottomMenuItem in bottomMenuItems"
          :key="bottomMenuItem.id"
          :active="
            bottomMenuItem.match
              ? bottomMenuItem.match(route.path)
              : route.path.startsWith(bottomMenuItem.to)
          "
          :icon="bottomMenuItem.icon"
          :to="bottomMenuItem.to"
        >
          {{ $t(bottomMenuItem.text) }}
        </BottomMenuLink>

        <BottomMenuLink :icon="BellIcon" @click="notifications.show = true">
          {{ $t("Notifications") }}

          <div v-if="auth.unreadNotificationCount" class="relative ml-3">
            <div
              class="animate-ping absolute h-6 w-6 rounded-full bg-orange-700"
            ></div>

            <div
              class="relative h-6 w-6 rounded-full bg-orange-700 flex items-center justify-center text-white text-sm"
            >
              {{ auth.unreadNotificationCount }}
            </div>
          </div>
        </BottomMenuLink>

        <BottomMenuLink
          :icon="ArrowLeftStartOnRectangleIcon"
          @click="auth.signOut"
        >
          {{ $t("Sign Out") }}
        </BottomMenuLink>
      </div>
    </div>

    <div
      aria-live="assertive"
      class="z-20 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
      <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
        <transition-group
          enter-active-class="transform ease-out duration-300 transition"
          enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <UINotification
            v-for="notification in notifications.notifications"
            :key="notification.id"
            :headline="notification.headline"
            :body="notification.body"
            :level="notification.level"
            @close="notifications.remove(notification)"
          />
        </transition-group>
      </div>
    </div>

    <ConfirmModal />

    <NotificationSlideOver v-model:open="notifications.show" />
  </div>
</template>

<script setup>
import {
  ArrowLeftStartOnRectangleIcon,
  BellIcon,
} from "@heroicons/vue/24/outline/index";
import { useAuth } from "~/stores/auth.js";
import { UINotification } from "@ultrafedt/components";
import { useNotifications } from "~/stores/notifications.js";
import ConfirmModal from "~/components/Modals/ConfirmModal.vue";
import NotificationSlideOver from "~/components/SlideOvers/NotificationSlideOver.vue";
import BottomMenuLink from "~/components/BottomMenuLink.vue";
import { useMenu } from "~/composables/useMenu.js";
import { Squares2X2Icon } from "@heroicons/vue/24/outline/index.js";

useHead({
  htmlAttrs: {
    class: "h-full bg-orange-50",
  },
  bodyAttrs: {
    class: "h-full w-full",
  },
  titleTemplate: "%s - Ultrafedt POS Terminal",
});

const auth = useAuth();
const notifications = useNotifications();
const route = useRoute();

const bottomMenuItems = computed(() => {
  return auth.bottomMenuItems.map((bottomMenuItem) =>
    useMenu().find((existing) => existing.id === bottomMenuItem.target),
  );
});

const loadContext = async () => {
  if (!document.hidden) {
    await auth.loadContext();

    if (!auth.channelSettlement) {
      return navigateTo("/channel-settlements/create");
    }

    if (auth.channelSettlement.closed_at) {
      return navigateTo("/channel-settlements/close");
    }
  }
};

onMounted(() => document.addEventListener("visibilitychange", loadContext));
onBeforeUnmount(() =>
  document.removeEventListener("visibilitychange", loadContext),
);
</script>
