<template>
  <UISlideOver :open="open" @close="open = false">
    <UISlideOverHeading :title="$t('Notifications')" @close="open = false" />

    <div v-if="!loading" class="p-4">
      <div class="grid grid-cols-1 divide-y divide-gray-200 flex-grow">
        <div
          v-for="notification in notifications.data"
          :key="notification.id"
          :class="[
            'flex p-4 cursor-pointer',
            notification.attributes.read_at
              ? 'text-gray-700'
              : 'text-amber-600',
          ]"
          @click="navigate(notification)"
        >
          <div>
            <PrinterIcon class="h-12 w-12" />
          </div>

          <div class="flex-grow pl-4">
            <div v-if="notification.attributes.category === 'print-job-failed'">
              {{
                $t("A print job for {printer} failed.", {
                  printer: notification.attributes.data.printer_name,
                })
              }}
            </div>

            <div class="text-gray-400">
              {{ useDateTime(notification.attributes.created_at) }}
            </div>
          </div>
        </div>
      </div>

      <UIPaginator :pagination="notifications.meta" @navigate="page = $event" />
    </div>
  </UISlideOver>
</template>

<script setup>
import {
  UIPaginator,
  UISlideOver,
  UISlideOverHeading,
} from "@ultrafedt/components";
import { PrinterIcon } from "@heroicons/vue/24/outline/index";
import { useAuth } from "~/stores/auth.js";

const open = defineModel("open", {
  required: true,
  type: Boolean,
});

const auth = useAuth();
const notifications = ref(null);
const loading = ref(true);
const page = ref(1);

const load = async () => {
  notifications.value = await useApi("/notifications", {
    query: { "page[number]": page.value, "page[size]": 5 },
  });

  auth.resetUnreadNotifications();

  loading.value = false;
};

const navigate = async (notification) => {
  switch (notification.attributes.category) {
    case "print-job-failed":
      await navigateTo("/print-jobs");
  }

  open.value = false;
};

watch(open, async () => {
  await load();
});

watch(page, async () => {
  await load();
});
</script>
