<template>
  <AppModal
    :open="modal.isOpen"
    :headline="modal.headline"
    @close="modal.cancel()"
  >
    <div>
      <p class="text-center text-sm text-gray-500">{{ modal.text }}</p>
    </div>

    <div class="mt-4 grid grid-cols-2 gap-2">
      <AppButton background-color-class="bg-sky-600" @click="modal.cancel()">
        {{ $t("Cancel") }}
      </AppButton>

      <AppButton
        :icon-before="CheckIcon"
        background-color-class="bg-sky-600"
        @click="modal.confirm()"
      >
        {{ $t("OK") }}
      </AppButton>
    </div>
  </AppModal>
</template>
<script setup>
import { CheckIcon } from "@heroicons/vue/24/outline/index";
import { useModal } from "~/stores/modal.js";

const modal = useModal();
</script>
