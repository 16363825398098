<template>
  <component
    :is="component"
    :class="[
      active ? 'text-amber-400' : 'text-orange-50',
      'flex text-xl items-center mx-4 px-4 uppercase',
    ]"
    :to="to"
    @click="emit('click')"
  >
    <component :is="icon" class="h-8 w-8 mr-2" />
    <slot />
  </component>
</template>

<script setup>
const props = defineProps({
  to: {
    required: false,
    type: String,
    default: null,
  },

  active: {
    required: false,
    type: Boolean,
    default: false,
  },

  icon: {
    required: true,
    type: [Object, Function],
  },
});

const emit = defineEmits(["click"]);

const component = computed(() => {
  return props.to ? resolveComponent("NuxtLink") : "button";
});
</script>
